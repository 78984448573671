body {
    margin: 0;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

.card {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.letters {
    font-size: 36px;
    font-weight: bold;
    display: flex;
    justify-content: space-evenly;
}

@media (max-width: 500px) {
    .words {
        flex-direction: column;
    }

    .words img {
        max-width: 80vw;
        max-height: 80vh;
    }
}

.words {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    flex-grow: 2;
}

.words img {
    max-width: 40vw;
    max-height: 40vh;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.word {
    width: 50%;
    padding: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
}

.buttons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.letters span, .words img {
    cursor: pointer;
}

